<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['processedFoodIntake'])">
      <single-answer-question
        title="label.processedFoodIntake"
        :value="processedFoodIntake"
        :options="$options.foodIntakeOptions"
        dysfunction="oxidative-stress"
        @input="onFieldChange('processedFoodIntake', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['antiOxidantIntake'])"
    >
      <single-answer-question
        id="anti-oxidant-intake"
        title="label.antiOxidantIntake"
        :value="antiOxidantIntake"
        :options="$options.antiOxidantIntakeOptions"
        dysfunction="oxidative-stress"
        @input="onFieldChange('antiOxidantIntake', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import { DISCOMFORT, FREQUENCY } from '@/modules/questionnaire/api/constants';

const FOOD_INTAKE_OPTIONS = [
  { value: DISCOMFORT.LOW, text: 'processedFoodIntake.low' },
  { value: DISCOMFORT.MEDIUM, text: 'processedFoodIntake.medium' },
  { value: DISCOMFORT.HIGH, text: 'processedFoodIntake.high' },
  { value: DISCOMFORT.VERY_HIGH, text: 'processedFoodIntake.veryHigh' }
];

const ANTI_OXIDANT_INTAKE_OPTIONS = [
  { value: FREQUENCY.NEVER, text: 'antiOxidantIntake.never' },
  { value: FREQUENCY.RARELY, text: 'antiOxidantIntake.rarely' },
  { value: FREQUENCY.SOMETIMES, text: 'antiOxidantIntake.sometimes' },
  { value: FREQUENCY.VERY_OFTEN, text: 'antiOxidantIntake.veryOften' },
  { value: FREQUENCY.ALWAYS, text: 'antiOxidantIntake.always' }
];

export default {
  name: 'NutritionTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  foodIntakeOptions: FOOD_INTAKE_OPTIONS,
  antiOxidantIntakeOptions: ANTI_OXIDANT_INTAKE_OPTIONS,
  props: {
    processedFoodIntake: {
      type: String,
      default: ''
    },
    antiOxidantIntake: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped></style>
